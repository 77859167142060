<template>
  <base-layout page-title="Success">
    <template v-slot:main-content>
      <div id="container" class="ion-padding">
        <h2>Congratulations</h2>
        <p>Your VEDA is ready to use in off-line mode</p>
        <p>Your VEDA is installed and ready to use for energy metering only. You can interact with your VEDA using the the LCD screen on the front of the meter.</p>
        <p>To connect your meter online, scan the QR Code on the meter and restart the connection process.</p>
      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from './BaseLayout.vue'

export default {
  name: 'OfflineMode',
  components: {
    BaseLayout
  }
}
</script>